.superstar-menu {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
  position: relative;
  z-index: 2;
  padding: 50px;
}

.superstar-item {
  cursor: pointer;
  margin: 10px;
  text-align: center;
}

.superstar-image {
  object-fit: cover;
  border-radius: 0%;
  background-color: transparent;
  display: block;
  width: 100%;
  height: 100%;
  position: relative; /* Ensure the image is in its own stacking context */
  z-index: 1; /* Move the image above the background */
}

.superstar-item {
  position: relative;
  width: 100px; /* Adjust the size as needed */
  height: 100px; /* Adjust the size as needed */
}

.superstar-item::before {
  content: '';
  position: absolute;
  top: auto;
  left: 0;
  width: 100%;
  height: 85%;
  background-color: black;
  z-index: 0; /* Move the background behind the content */
  bottom: 0; /* Align the background to the bottom */
}
iframe#superstar-youtube-player {
  width: 100%;
  height: 300px;
}
