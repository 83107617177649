.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* CSS */
button {
  background-color: #222;
  border-radius: 4px;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Farfetch Basis", "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
  max-width: none;
  min-height: 44px;
  min-width: 10px;
  outline: none;
  overflow: hidden;
  padding: 9px 20px 8px;
  position: relative;
  text-align: center;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
}

button:hover,
button:focus {
  background-color: red;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

select:hover {
  background-color: red;
}
@font-face {
  font-family: "Microgramma-Bold";
  src: url("./assets/fonts/Microgramma\ D\ Extended\ Bold.otf ")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MicrogrammaEF Extend Medium";
  src: url("./assets/fonts/MicrogrammaEF Extend Medium.otf") format("opentype");

  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Microgramma-Bold", sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* This ensures that the content takes at least the full height of the viewport */
  margin: 0;
}
iframe {
  width: 100%;
  height: 300px;
}
.footer {
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  position: relative;
  width: 100%;
  bottom: 0;
}
@media screen and (max-width: 600px) {
  .footer {
    position: relative; /* Change to relative for smaller screens */
    font-size: small;
  }
}
